import * as React from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SeoComponent from "../../components/seo"

const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image)

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <SeoComponent title={data.mdx.frontmatter.title} />

      <div className="bg-caffeinity-pink overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div>
              <h2 className="text-base text-caffeinity-green-dark font-semibold italic uppercase">
                <time dateTime={data.mdx.frontmatter.date}>{data.mdx.frontmatter.date}</time>
              </h2>
              <h3
                className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-caffeinity-green-light sm:text-4xl">
                {data.mdx.frontmatter.title}
              </h3>
            </div>
          </div>


          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <GatsbyImage
                      image={image}
                      alt={data.mdx.frontmatter.hero_image_alt}
                      imgClassName="rounded-lg shadow-lg object-cover object-center"
                    />
                   </div>
                  <figcaption className="mt-3 flex text-sm text-gray-500">

                    <span className="ml-2">{data.mdx.frontmatter.hero_image_description}</span>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0text-lg text-gray-600 text-justify blog">
              <MDXRenderer>
                {data.mdx.body}
              </MDXRenderer>
            </div>

            <Link to="/blog/" className="btn-primary">Zurück</Link>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export const query = graphql`
query MyBlogQuery($id: String) {
  mdx(id: {eq: $id}) {
    body
    frontmatter {
      title
      date(formatString: "MMMM DD, YYYY")
      hero_image_alt
      hero_image_description
      hero_image {
        childImageSharp {
         gatsbyImageData(formats: AUTO quality: 40)
        }
      }
    }
  }
}`

export default BlogPost